<template>
  <div class="assets-liabilities">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'AssetsLiabilities'
}
</script>

<style scoped>
.assets-liabilities{
  display: flex;
  min-height: 76%;
}
</style>
